import axios from 'axios'
import React, { Component } from 'react'
import Conn from './Conn';
import Commons from './Commons';

class Repository {
    static page = (Repository.page < 1 || Repository.page == undefined) ? 1 : Repository.page;
    static size = (Repository.size < 1) ? 50 : Repository.size;
    static server = Conn.wholePath.name;
    // static url = "http://" + Repository.server + ":8089/guru/api"

    static headers = Conn.LoginToken
    static getHeaders = Conn.GetToken

    findAccountById(id) {
        return axios.get(Repository.server + "/account/" + id, { headers: Repository.getHeaders }).catch(() => { Commons.RedirectToLogin() })
    }
    findCategories() {
        return axios.get(Repository.server + "/")
    }
    Login(authRequest) {

        return axios.post(Conn.server.name + Conn.port.val + "authenticate", authRequest, { headers: Repository.headers }
        )
    }
    findCategoriesCount() {
        return axios.get(Repository.server + "/count/",
            {
                withCredentials: true
            }
        )
    }
    findProfile() {
        return axios.get(Repository.server + "/profile/")
    }
    findAccount() {
        return axios.get(Repository.server + "/account/", { headers: Repository.getHeaders }).catch(() => { Commons.RedirectToLogin() })
    }
    disableUser(status, id) {
        return axios.get(Repository.server + "/account/disableOrEnable/users/byStatus/" + status + '/' + id, { headers: Repository.getHeaders })
    }
    findAccountByStatus(status) {
        return axios.get(Repository.server + "/account/users/byStatus/" + status, { headers: Repository.getHeaders }).catch(() => { Commons.RedirectToLogin() })
    }
    findAccount_category() {
        return axios.get(Repository.server + "/category/", { headers: Repository.getHeaders })
            .catch(() => Commons.RedirectToLogin())
    }
    findHw_movement(SearchByDateOnly) {
        return axios.post(Repository.server + "/hwmovement/searchbydate/sameday/noType/all", SearchByDateOnly, { headers: Repository.getHeaders })
            .catch((e) =>
                alert(e)
                //() => Commons.RedirectToLogin()
            )
    }
    findWhmByType(type) {
        return axios.get(Repository.server + "/hwmovement/whmByType/" + type, { headers: Repository.getHeaders })
        // .catch(() => Commons.RedirectToLogin())
    }

    finditemsCategory() {
        return axios.get(Repository.server + "/itemsCategory", { headers: Repository.getHeaders }).catch(() => Commons.RedirectToLogin())
    }
    findItem_categoryById(id) {
        return axios.get(Repository.server + "/itemsCategory/itemsCategory/" + id, { headers: Repository.getHeaders })
        //.catch(() => Commons.RedirectToLogin())
    }
    findProfile() {
        return axios.get(Repository.server + "/profile", { headers: Repository.getHeaders }).catch(() => Commons.RedirectToLogin())
    }
    findSale_purchase_journal() {
        return axios.get(Repository.server + "/Sale_purchase_journal", { headers: Repository.getHeaders }).catch(() => Commons.RedirectToLogin())
    }
    findSales() {
        return axios.get(Repository.server + "/sales", { headers: Repository.getHeaders }).catch(() => Commons.RedirectToLogin())
    }
    findPurchases() {
        return axios.get(Repository.server + "/purchases", { headers: Repository.getHeaders }).catch(() => Commons.RedirectToLogin())
    }
    findItem_category() {
        return axios.get(Repository.server + "/itemsCategory",
            { headers: Repository.getHeaders })
            .catch(() => Commons.RedirectToLogin())
    }

    findItems(page, size) {
        let config = {
            headers: Repository.getHeaders,
            params: {
                page: page, size: size
            },

        }
        return axios.get(Repository.server + "/items", config)
            .catch((err) =>
                Commons.RedirectToLogin()
            )
    }


    findItemsCount() {
        return axios.get(Repository.server + "/items/itemsCount/", { headers: Repository.getHeaders }).catch((err) => alert(err)// Commons.RedirectToLogin()
        )
    }
    findItemsById(id) {
        return axios.get(Repository.server + "/items/items/" + id, { headers: Repository.getHeaders }).catch((err) => alert(err)// Commons.RedirectToLogin()
        )
    }

    
    
    
    findItemLike_ByDateLike(mdl_WhMovtItemLike) {
        return axios.post(Repository.server + "/hwmovement/searchby/date/item/like/type", mdl_WhMovtItemLike, { headers: Repository.getHeaders })

    }
    findItemByDate(Mdl_SearchItemDate_itemName) {
        return axios.post(Repository.server + "/Sale_purchase_journal/searchItem", Mdl_SearchItemDate_itemName, { headers: Repository.getHeaders })

    }
    countWmvntByType(Mdl_SearchItemDate_itemName) {
        return axios.post(Repository.server + "/Sale_purchase_journal/countWmvntByType", Mdl_SearchItemDate_itemName, { headers: Repository.getHeaders })

    }
    findMostMoved(Mdl_SearchItemDate_itemName) {
        return axios.post(Repository.server + "/items/mostMoved", Mdl_SearchItemDate_itemName, { headers: Repository.getHeaders })

    }


    findHw_movementByReference(reference) {
        return axios.get(Repository.server + "/hwmovement/" + reference, { headers: Repository.getHeaders })
        // .catch(() => Commons.RedirectToLogin())
    }
    allNopagination() {
        return axios.get(Repository.server + "/items/search/items/allnopagination", { headers: Repository.getHeaders })
        // .catch(() => Commons.RedirectToLogin())
    }
    findItemssbyname(itemname) {
        return axios.get(Repository.server + "/items/itembynamelike/" + itemname, { headers: Repository.getHeaders })
    }
    findHw_movementById(id) {
        return axios.get(Repository.server + "/hwmovement/" + id, { headers: Repository.getHeaders })
    }
    conf() {

        return axios.get(Repository.server + "/items/conf", { headers: Repository.getHeaders })
    }



}

export default new Repository()