import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navbar";
import "./components/Styles/App.css"
import "./components/Styles/commons.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { heart as wedding } from 'react-icons-kit/icomoon/heart'

import { ic_view_week_outline as struc } from 'react-icons-kit/md/ic_view_week_outline'
import SideBar from "./components/Navbar/SideBar";
import Dashboard from "./components/pages/Dashboard";
import "./components/Styles/SideBar.css"
import About from "./components/pages/About";
import AccountPage from "./components/pages/AccountPage";
import Account_category from "./components/pages/Account_category";
import Profile from "./components/pages/Profile";
import Items from "./components/pages/items";
import Sales from "./components/pages/Sales";
import Hwmovement from "./components/pages/Hw_movement";
import Sale_purchase_journal from "./components/pages/Sale_purchase_journal";
import Purchases from "./components/pages/Purchases";
import ItemCategory from "./components/pages/item_category";
import LoggedInPage from "./components/pages/LoggedInPage";
import { RequireAuth } from "react-auth-kit";
import Admin from "./components/pages/Admin";
import Damage from "./components/pages/Damage";
import Return from "./components/pages/Return";
import Configure from "./components/pages/Configure";
import Changepassword from "./components/pages/Changepassword";


function App() {
  // const [category, setCategory] = useState(null)
  // const categoryDetails = useMemo(() => ({ category, setCategory }), [category, setCategory])

  return (
    <div className="App">

      <Navbar />
      <Routes>
        <Route path="/" element={
          <Dashboard />} />

        <Route path="/account" element={
          <RequireAuth loginPath="/login">
            <AccountPage />
          </RequireAuth>
        } />
        <Route path="/dashboard" element={
          <RequireAuth loginPath="/login">
            <Dashboard />
          </RequireAuth>
        } />

        <Route path="/acc_category" element={
          <RequireAuth loginPath="/login">
            <Account_category />
          </RequireAuth>} />
        <Route path="/profile" element={
          <RequireAuth loginPath="/login">
            <Profile />
          </RequireAuth>} />
        <Route path="/itemcategory" element={

          <ItemCategory />
        } />
        <Route path="/item" element={
          <Items />
        } />
        <Route path="/movement" element={
          <RequireAuth loginPath="/login">
            <Hwmovement />
          </RequireAuth>} />
        <Route path="/purchase" element={
          <RequireAuth loginPath="/login">
            <Purchases />
          </RequireAuth>} />
        <Route path="/sale" element={
          <RequireAuth loginPath="/login">
            <Sales />
          </RequireAuth>} />
        <Route path="/salepujournal" element={
          <RequireAuth loginPath="/login">
            <Sale_purchase_journal />
          </RequireAuth>} />

        <Route path="/admin" element={
          <RequireAuth loginPath="/login">
            <Admin />
          </RequireAuth>} />
        <Route path="/damage" element={
          <RequireAuth loginPath="/damage">
            <Damage />
          </RequireAuth>} />
        <Route path="/return" element={
          <RequireAuth loginPath="/return">
            <Return />
          </RequireAuth>} />
        <Route path="/configure" element={
          <RequireAuth loginPath="/configure">
            <Configure />
          </RequireAuth>} />
        <Route path="/changepassword" element={
          <RequireAuth loginPath="/changepassword">
            <Changepassword />
          </RequireAuth>} />

        {/* Login */}
        <Route path="/login" element={<LoggedInPage />} />
      </Routes>

    </div >
  );
}

export default App;
