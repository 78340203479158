import React, { useEffect, useState } from 'react'

function Dashboard() {
    const [username, setUsername] = useState()
    const [userType, setUserType] = useState()
    useEffect(()=>{
        setUsername(localStorage.getItem('token'))
        setUserType(localStorage.getItem('catname'))

    })

    return (
        <div className='dashboardHome sm_txt_12 md_txt_12'  >
            <span>
                Welcome To Stock Management system
            </span>

            <div className='homeBox_anim' style={{zIndex: '13'}}>
                Box
            </div>
        </div>
    )
}

export default Dashboard
